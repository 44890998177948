.App {
  min-height: 100vh;
  text-align: center;
  // @media only screen and (max-width: 500px) {
  //   overflow-x: hidden;
  // }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  z-index: 1;
  width: calc(100% - 150px);
  display: flex;
  align-self: center;
}

@font-face {
  font-family: CodeLight;
  src: url('assets/font/PierSans-Light.otf');
}

@font-face {
  font-family: CodeBold;
  src: url('assets/font/PierSans-Bold.otf');
}

@font-face {
  font-family: CodeBlack;
  src: url('assets/font/PierSans-Black.otf');
}

@font-face {
  font-family: CodeMedium;
  src: url('assets/font/PierSans-Medium.otf');
}

@font-face {
  font-family: CodeRegular;
  src: url('assets/font/PierSans-Regular.otf');
}

span {
  font-family: CodeRegular;
}
